<template>
    <main>
        <Form ref="formValidate" :model="form" :rules="ruleValidate" :label-width="80">
            <FormItem label="密码" prop="oldPassword">
                <Input v-model="form.oldPassword" type="password" placeholder="请输入密码" style="width: 300px"></Input>
            </FormItem>
            <FormItem label="新密码" prop="password">
                <Input v-model="form.password" type="password" placeholder="请输入新密码" style="width: 300px"></Input>
            </FormItem>
            <FormItem label="确认密码" prop="confirmPassword">
                <Input v-model="form.confirmPassword" type="password" placeholder="请再次输入新密码" style="width: 300px"></Input>
            </FormItem>
            <FormItem>
                <Button type="primary" @click.native="save">保存</Button>
            </FormItem>
        </Form>

    </main>
</template>

<script>
    export default {
        name: 'MedifyUserPassword',
        data() {
            const validatePassword = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('确认密码不能为空'));
                } else if (value !== this.form.password) {
                    callback(new Error('新密码、确认密码不一致'));
                } else {
                    callback();
                }
            };
            return {
                form: {
                    oldPassword: '',
                    password: '',
                    confirmPassword: ''
                },
                ruleValidate: {
                    oldPassword: [
                        { required: true, message: '密码不能为空', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '新密码不能为空', trigger: 'blur' }
                    ],
                    confirmPassword: [
                        { required: true, message: '新密码不能为空', trigger: 'blur' },
                        { validator: validatePassword, trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            save() {
                this.$refs.formValidate.validate(v => {
                    if(v){
                        console.log(this.form)
                        this.post("/permission/user/password/medify", this.form).then(() => {
                            this.$message.success("修改成功")
                            this.$refs.formValidate.resetFields()
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>